import React from 'react'
import styled from 'styled-components'
import Bio from './Bio'
import Content from './Content'

const ArticleWrapper = styled.article`
  padding: 0 30px 30px;

  @media only screen and (max-width: 500px) {
    padding: 0;
  }
`

const ArticleFooter = styled.footer`
  position: relative;
  margin: 6rem 0 0;
  padding: 3rem 0 0;
  border-top: 1px solid #ececec;
`

class Article extends React.Component {
  render() {
    const { title, post } = this.props
    return (
      <ArticleWrapper>
        <Content
          title={title}
          content={post.text}
          date={post.date}
          tags={post.tags}
          translations=""
        />
      </ArticleWrapper>
    )
  }
}

export default Article
