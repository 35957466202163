import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Wrapper from '../components/Wrapper'
import WrapperEmbedded from '../components/WrapperEmbedded'
import Hero from '../components/Hero'
import Article from '../components/Article'
import PrevNextPost from '../components/PrevNextPost'
import SEO from '../components/SEO'
import Disqus from '../components/Disqus'

class BlogPostTemplate extends React.Component {

  formattedDate(date) {
    const dateObject = new Date(date)
    const yyyymmdd = dateObject
      .toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
        hour:  'numeric',
        minute:  'numeric',
        second:  'numeric'
      })
      .split('/')
      .reverse()
      .join('-')
    const friendlyDate = dateObject.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour:  'numeric',
      minute:  'numeric',
      second:  'numeric'
    })
    return friendlyDate
  }

  render() {
    const post = this.props.data.post
    const { previous, next } = this.props.pageContext
    const theTitle = "What @realDonaldTrump said on " + this.formattedDate(post.date)
    const friendlyDate = this.formattedDate(post.date)

    return (
      <Layout location={this.props.location}>
        <SEO
          title={theTitle}
          description={post.text}
          path={post.slug}
          isBlogPost
        />
        <WrapperEmbedded>
          <h1>On {friendlyDate} @realDonaldTrump said</h1>
        </WrapperEmbedded>
        <Wrapper>
          <Article title={theTitle} post={post} />
        </Wrapper>
        <WrapperEmbedded>
          <div id="371165508"></div>
        </WrapperEmbedded>
        <PrevNextPost previous={previous} next={next} />

        <WrapperEmbedded as="aside">
          <Disqus slug={post.slug} title={post.text} />
        </WrapperEmbedded>

      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
query BlogPostBySlug($slug: String!) {
  post: mysqlTweets(slug: {eq: $slug}) {
    text
    date
    slug
    tags {
      tag
    }
  }
}
`
